<template>
    <div class="modal fade addItemModal addProjectsModal add-item-modal add-project-modal" data-bs-backdrop="static" id="addProjectModal"
         tabindex="-1" aria-hidden="true" ref="addProjectModal">
        <div class="modal-dialog custom-xl-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">
                        <template v-if="isEditing">
                            <h5 class="modal-title">Edit Project</h5>
                            <p>Use the input fields below to edit the existing project</p>
                        </template>

                        <template v-else>
                            <h5 class="modal-title">Add New Project</h5>
                            <p>Use the input fields below to track a new project</p>
                        </template>
                    </div>
                    <a href="#" ref="closeBtn" class="close-modal" data-bs-dismiss="modal"
                       aria-label="Close">Close <i class="fas fa-times"></i></a>
                </div>

                <div class="modal-body">
                    <form action="" class="add-item-form" v-if="!isLoading">
                        <!--            Title-->
                        <div class="form-group" style="max-width: 210px" @click="focusOnClick">
                            <label for="actionName" class="required-field">Title</label>
                            <input v-model="titleValue" type="text" id="actionName"
                                   placeholder="Write the project name"
                                   @focusout="validInputSet('titleValue', 'inputTitleSet')"
                                   :class="{ 'field-set': titleValue }" class="item" maxlength="160" autocomplete="off"
                                   v-on:keydown.enter="onEnter($event)">
                            <div class="msg-error" v-if="!$v.titleValue.required &amp;&amp; $v.titleValue.$dirty">
                                Field is required
                            </div>
                        </div>

                        <!--            Responsible user-->
                        <div class="form-group" style="max-width: 180px" :class="{ 'form-group--error': $v.responsibleUserSelected.$error }"
                             @click="focusOnClick" v-on:keydown.enter="onEnter($event, '#measureContainerId textarea')">
                            <label for="responsibleUser" class="required-field">Responsibility</label>
                            <div class="add-user">
                                <people-picker :options="filteredUsers" :user="responsibleUserSelected" label="ownerName" id="id" v-on:keyup.enter="onEnter($event)" ref="peoplePicker"></people-picker>
                            </div>
                            <div class="msg-error"
                                 v-if="!$v.responsibleUserSelected.required &amp;&amp; $v.responsibleUserSelected.$dirty">
                                Field is required
                            </div>
                        </div>

                        <!--            Measure-->
                        <div class="form-group measure-text-area position-relative" style="max-width: 130px" @click="focusOnClick" id="measureContainerId">
                            <label for="measure" class="required-field">Measure</label>
                            <div class="text-area-container measure-text-area position-absolute"
                                 :class="{ 'field-set': measureValue }">
                                <textarea name="measure" id="measure" cols="12" rows="10"
                                          @focusout="validInputSet('measureValue', 'measureSet')"
                                          v-model="measureValue" :class="{ 'field-set': measureValue }"
                                          maxlength="120" ref="measureInput" v-on:keydown.enter="onEnter($event, '#statusId a.option-selected')"></textarea>

                            </div>
                            <div class="msg-error"
                                 :class="{ 'field-set': measureValue }"
                                 style="position: absolute; left: 0; top: 142px;"
                                 v-if="!$v.measureValue.required &amp;&amp; $v.measureValue.$dirty">
                                Field is
                                required
                            </div>
                        </div>

                        <!--            Status-->
                        <div class="form-group" style="max-width: 82px" @click="focusOnClick($event, '#statusId a.option-selected')"
                             v-on:keydown.enter="isSelectedListChanged ? onEnter($event, '#issuesId textarea') : null">
                            <label class="required-field">Status</label>
                            <div class="jdi-custom-select" id="statusId">
                                <a href="#" v-if="statusOptionSelected === 'Select'" class="jdi-select-box"
                                   @click="statusSelectOpen = !statusSelectOpen">
                                    <span>{{ statusOptionSelected }}</span>
                                    <img src="/images/Jdi/icon-select-dropdown.svg" alt="">
                                </a>

                                <a href="#" v-else class="option-selected"
                                   @click="statusSelectOpen = !statusSelectOpen">

                                    <span v-if="statusOptionSelected.id == 1" class="active">
                                        <i class="fas fa-circle"></i>
                                    </span>
                                    <span v-else-if="statusOptionSelected.id == 2" class="delayed">
                                        <i class="fas fa-triangle"></i>
                                    </span>
                                    <span v-else-if="statusOptionSelected.id == 3" class="stopped">
                                        <i class="fas fa-diamond"></i>
                                    </span>
                                </a>

                                <ul class="options-list" v-if="statusSelectOpen">
                                    <li v-for="(status, i) in statuses" :key="'status-' + i">
                                        <a href="#" class="list-item d-flex align-items-center" @click.prevent="
                        selectStatus(
                          status.id,
                          status.statusName,
                          status.color,
                          'statusSelected',
                          'statusSet'
                        )
                      ">
                                            <span class="status-color-indicator">
                                                <i :class="'fas '+status.icon" :style="'color: '+status.color+';'"></i>
                                            </span>
                                            {{ status.statusName }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <input type="hidden" name="list" v-model="statusSelected">
                        </div>

                        <!--            Issues-->
                        <div class="form-group issues-text-area" style="max-width: 140px" @click="focusOnClick">
                            <label for="issues">Issues</label>

                            <div class="text-area-container issues-text-area position-absolute" id="issuesId"
                                 :class="{'field-set': issuesValue }">
                                <textarea class="" name="issues" id="issues" cols="12" rows="10"
                                          v-model="issuesValue"
                                          @focusout="validInputSet('issuesValue', 'issuesSet')" :class="{ 'field-set': issuesValue }"
                                          maxlength="200" v-on:keydown.enter="onEnter($event, '#selectedListId')"></textarea>
                            </div>

                        </div>

                        <!--            List-->
                        <div class="form-group" style="max-width: 90px" @click="focusOnClick" v-on:keydown.enter="onEnter($event, '#nextStepsContainerId textarea')">
                            <label class="required-field">List</label>

                            <div class="jdi-search-select">
                                <div class="select-list-container" v-if="!(selectedList && selectedList.id)">
                                    <v-select class="list-select" v-model="selectedList" label="name"
                                              :options="projectsListsCom" placeholder="Select"
                                              @input="validInputSet('selectedList', 'listSelectSet')"></v-select>
                                </div>
                                <div v-else class="result-select" :style="'background: #' + selectedList.colour">
                                    <span>{{ selectedList.name }}</span>
                                    <a href="#" id="selectedListId" @click.prevent="deselectList()"><i class="fas fa-times"></i></a>
                                </div>
                            </div>

                            <div class="msg-error"
                                 v-if="!$v.selectedList.required &amp;&amp; $v.selectedList.$dirty">
                                Field is
                                required
                            </div>
                        </div>

                        <!--            NextSteps-->
                        <div class="form-group nextSteps-text-area position-relative" style="max-width: 130px" id="nextStepsContainerId" @click="focusOnClick">
                            <label for="nextSteps" class="required-field">Next Steps</label>
                            <div class="
                  text-area-container
                  nextSteps-text-area
                  position-absolute
                " :class="{'field-set': nextStepsValue}">
                                <textarea class="" name="nextSteps" id="nextSteps" cols="12" rows="10"
                                          v-model="nextStepsValue"
                                          @focusout="validInputSet('nextStepsValue', 'NextStepsSet')"
                                          :class="{ 'field-set': nextStepsValue }" maxlength="160" v-on:keydown.enter="onEnter($event)"></textarea>
                            </div>
                            <div class="msg-error"
                                 :class="{'field-set': nextStepsValue }"
                                 style="position: absolute; left: 0; top: 142px;"
                                 v-if="!$v.nextStepsValue.required &amp;&amp; $v.nextStepsValue.$dirty">
                                Field is
                                required
                            </div>
                        </div>

                        <!--            Date picker-->
                        <div class="form-group" style="max-width: 185px" @click="focusOnClick">
                            <label class="required-field">Target Date</label>
                            <bs-input-group>
                                <bs-form-input id="targetDate" class="date" :value="dateFormat(date)" type="text" readonly
                                              placeholder="DD-MMM-YY" autocomplete="off" v-on:keydown.enter="onEnter($event)"></bs-form-input>
                                <bs-form-datepicker v-model="date" aria-controls="targetDate"></bs-form-datepicker>
                            </bs-input-group>
                        </div>
                    </form>
                    <div v-else class="text-center">
                        <div class="spinner-border text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger" style="display: none; width: 70%; margin: auto auto 10px auto; text-align: center;" id="saveWithInvalidUserAlert">The person assigned to this record could not be found. Please reassign this record to another person and try again.</div>
                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                        <div v-if="editRecord.createdBy" class="jdi-label">Created by {{editRecord.createdBy}} on {{$moment(editRecord.createdOn).format("DD-MMM-YY")}} at {{$moment(editRecord.createdOn).format("HH:mm")}} </div>
                        <div v-if="editRecord.modifiedBy" class="jdi-label">Modified by {{editRecord.modifiedBy}} on {{$moment(editRecord.modifiedOn).format("DD-MMM-YY")}} at {{$moment(editRecord.modifiedOn).format("HH:mm")}} </div>
                    </div>
                    <div class="modal-footer-rigt-container">
                        <a href="#" class="save-modal-button jdi-green-button" v-on:click.prevent="onAddProject()">
                            Save to project list
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

import { ProjectList } from '../../Models/ProjectList';
import { Project } from '../../Models/Project';
import { User } from '../../Models/User';
import { Status } from '../../Models/Status';
import { BaseListEntity } from '../../Models/BaseListEntity';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import VueSimpleSuggest from 'vue-simple-suggest';
import { VueSelect } from 'vue-select';

import PeoplePicker from '../PeoplePicker/PeoplePicker.vue';
import componentNavigationMixins from '../../mixins/componentNavigationMixins';
import { ContactToCreateFromEntity } from '../../Models/Contact';
import { AssignType } from '../../Models/Enums';
import BsInputGroup from '../BsInputGroup.vue';
import BsTextInput from '../BsTextInput';
import BsFormDatepicker from '../BsFormDatepicker';
import ApiRequestor from '../../Services/ApiRequestor';
import moment from 'moment';
import _ from 'lodash';

@Component({
    mixins: [validationMixin, componentNavigationMixins],
    name: 'add-project-modal',
    template: '#add-project-modal-id',
    components: {
        'vue-simple-suggest': VueSimpleSuggest,
        'v-select': VueSelect,
        'people-picker': PeoplePicker,
        'bs-input-group': BsInputGroup,
        'bs-form-input': BsTextInput,
        'bs-form-datepicker': BsFormDatepicker,
    },
    validations: {
        titleValue: {
            required,
        },

        responsibleUserSelected: {
            required,
        },

        measureValue: {
            required
        },

        selectedList: {
            required,
        },

        nextStepsValue: {
            required
        },
    }
})

export default class AddProjectModal extends Vue {

    @Watch('isEditing') onIsEditingChanged(newVal: any, oldVal: any) {
        this.setProjectData();
    }

    @Watch('selectedListFromFilter') onListChanged(newVal: any, oldVal: any) {
        this.setListFromFilter();
    }

    @Watch('$store.state.selectedResponsibleUser') onSelectedResponsibleUserChanged(newVal: User, oldVal: User) {
        if (!newVal) {
            this.responsibleUserSelected = {} as User;
        }
        else {
            this.responsibleUserSelected = newVal;
        }

        this.validInputSet('responsibleUserSelected', 'addUserSet');
    }

    @Watch('editRecord') onRecordChanged(newVal: Project, oldVal: Project) {
        if (oldVal == undefined || newVal.id !== oldVal.id) {
            this.setProjectData();
        }
    }

    @Watch('$store.state.hasContactBeenCreated') onContactHasBeenCreatedChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.isLoading = true;
            const me = this;
            ApiRequestor.get('GetOwners')
                .then((res) => {
                    this.responsibleUsers = res.data as User[];
                    const contactDetails: ContactToCreateFromEntity = this.$store.state.contactToCreateFromEntity;
                    if (contactDetails != null && contactDetails.project != null) {
                        this.titleValue = contactDetails.project.name;
                        this.measureValue = contactDetails.project.measure;
                        this.nextStepsValue = contactDetails.project.nextSteps;
                        this.issuesValue = contactDetails.project.issues || '';
                        this.date = contactDetails.project.nextStepDate as string;
                        this.archived = contactDetails.project.archived as boolean;
                        this.selectedList = contactDetails.project.list as ProjectList;

                        const status = this.statuses.find((x) => x.id == contactDetails.project.status) as Status;

                        this.selectStatus(
                            status.id,
                            status.statusName,
                            status.color,
                            'statusSelected',
                            'statusSet'
                        );

                        if (contactDetails.id) {
                            this.responsibleUserSelected = {
                                id: contactDetails.id,
                                ownerName: contactDetails.name,
                                type: AssignType.Contact,
                                isOwnerImageUploaded: false,
                            } as User;

                        }

                    }
                    this.$store.dispatch('setNewContactCreation', {});
                    this.$store.dispatch('setRefreshContacts', false);
                    this.isLoading = false;
                    setTimeout(() => {
                        (<PeoplePicker>me.$refs.peoplePicker).selectUser(me.responsibleUserSelected);
                    }, 0);
                })
                .catch((e) => {
                    console.log(e.message);
                    this.responsibleUsers = [];
                });

        }
    }

    @Watch('$store.state.contactToCreateFromEntity') onContactToCreateChanged(newVal: ContactToCreateFromEntity, oldVal: ContactToCreateFromEntity) {
        if (newVal && newVal.shouldBeCreated && newVal.shouldBeCreated != oldVal.shouldBeCreated) {
            newVal.project = {
                name: this.titleValue,
                measure: this.measureValue,
                issues: this.issuesValue,
                nextStepDate: this.date,
                nextSteps: this.nextStepsValue,
                archived: this.archived,
                list: this.selectedList,
                status: this.statusOptionSelected.id
            } as Project;

            this.$store.dispatch('setNewContactCreation', newVal);
        }
    }

    private editRecord: Project = {} as Project;

    private date: string = new Date().toISOString().slice(0, 10);

    private autoCompleteStyle = {
        vueSimpleSuggest: '',
        inputWrapper: '',
        defaultInput: 'users-select',
        suggestions: 'add-user-dropdown-menu',
        suggestItem: 'add-user-dropdown-list-item',
    };

    private isLoading = false;
    private archived = false;
    private inputTitleSet = false;
    private addUserSet = false;
    private measureSet = false;
    private statusSet = false;
    private issuesSet = false;
    private listSelectSet = false;
    private NextStepsSet = false;

    private measureValue = '';
    private issuesValue = '';
    private nextStepsValue = '';

    private responsibleUsers: User[] = [];
    private lists: [] = [];
    private listSelectOpen = false;
    private listOptionSelected = 'Select';

    private projectLists: ProjectList[] = [];
    private statuses: Status[] = [{
        id: 1,
        color: '#009933',
        statusName: 'Active',
        icon: 'fa-circle'
    },
    {
        id: 2,
        color: '#FFCB5B',
        statusName: 'Delayed',
        icon: 'fa-triangle'
    },
    {
        id: 3,
        color: '#E95A6C',
        statusName: 'Stopped',
        icon: 'fa-diamond'
    },
    ];
    private statusSelectOpen = false;
    private statusOptionSelected: Status = {
        id: 1,
        color: '#009933',
        statusName: 'Active',
        icon: 'fa-circle'
    };
    private titleCreated = false;
    private titleValue = '';
    private responsibleUserSelected: User = {} as User;
    private selectedList: ProjectList = {} as ProjectList;
    private statusSelected = '';
    private recordId = '';
    private isSelectedListChanged = false;

    get isEditing(): boolean {
        return this.$store.state.isEditing;
    }

    get selectedListFromFilter() {
        return this.$store.state.selectedListFromFilter;
    }

    get projectsListsCom(): ProjectList[] {
        if (this.responsibleUserSelected && this.responsibleUserSelected.id && this.responsibleUserSelected.type == 0) {
            const res = this.projectLists.filter((l: BaseListEntity) => !l.isPrivate || l.isAvailableForCurrentUser || l.members.some((m: User) => m.id == this.responsibleUserSelected.id));
            return res;
        }

        return this.projectLists;
    }

    get filteredUsers() {
        if (this.selectedList && this.selectedList.id && this.selectedList.isPrivate) {
            return this.responsibleUsers.filter((u: User) => u.type == 1 || (this.selectedList.members && this.selectedList.members.some((m) => m.id == u.id)) || u.id == this.editRecord.ownerId);
        }
        return this.responsibleUsers;
    }

    private deselectList() {
        this.selectedList = <ProjectList>{};
    }

    setListFromFilter(): void {
        if (this.selectedListFromFilter) {
            this.selectedList = this.selectedListFromFilter as ProjectList;
            this.statusSet = true;
            this.listSelectSet = true;
        }
        else {
            this.selectedList = {} as ProjectList;
            this.statusSet = false;
            this.listSelectSet = false;
        }
    }

    validInputSet(value: string, inputNameSet: string): void {
        const modal: any = this;
        if (!_.isEmpty(modal[value])) {
            modal[inputNameSet] = true;
        }
    }

    selectStatus(id: number, status: string, color: string, inputValue: string, inputNameSet: string): void {
        this.statusOptionSelected = {
            id: id,
            statusName: status,
            color: color
        };
        this.statusSelectOpen = false;
        this.statusSelected = status;
        const modal: any = this;
        this.isSelectedListChanged = true;
        if (!_.isEmpty(modal[inputValue])) {
            modal[inputNameSet] = true;
            this.validInputSet('selectedList', 'listSelectSet');
        }
    }

    getDropdownLists(): void {
        ApiRequestor.get('GetOwners')
            .then((res) => this.responsibleUsers = res.data)
            .catch((e) => {
                console.log(e.message);
                this.responsibleUsers = [];
            });
        ApiRequestor.get('GetMemberProjectLists')
            .then((res) => this.projectLists = res.data)
            .catch((e) => {
                console.log(e.message);
                this.projectLists = [];
            });
    }

    setProjectData(): void {
        if (this.isEditing) {
            this.editRecord = this.$store.state.editRegisterItem;
            this.titleValue = this.editRecord.name;
            this.responsibleUserSelected = {
                id: this.editRecord.ownerId,
                ownerName: this.editRecord.ownerName,
                type: this.editRecord.ownerType,
                isOwnerImageUploaded: this.editRecord.isOwnerImageUploaded
            } as User;
            this.measureValue = this.editRecord.measure;
            this.nextStepsValue = this.editRecord.nextSteps;
            this.issuesValue = this.editRecord?.issues?.length ? this.editRecord.issues : '';
            this.date = this.editRecord.nextStepDate as string;
            this.archived = this.editRecord.archived as boolean;
            this.recordId = this.editRecord.id;
            this.selectedList = this.editRecord.list as ProjectList;
            if (!this.selectedList.members) {
                const list = this.projectLists.find((l) => l.id == this.selectedList.id);
                if (list && list.members && list.members.length > 0) {
                    this.selectedList.members = list.members;
                }
            }
            const status = this.statuses.find((x) => x.id == this.editRecord.status) as Status;
            this.selectStatus(
                status.id,
                status.statusName,
                status.color,
                'statusSelected',
                'statusSet'
            );
            (<PeoplePicker>this.$refs.peoplePicker).selectUser({
                id: this.editRecord.ownerId,
                ownerName: this.editRecord.ownerName,
                type: this.editRecord.ownerType,
                isOwnerImageUploaded: this.editRecord.isOwnerImageUploaded,
            } as User);
        }
        else {
            this.cleanForm();
        }
    }

    onAddProject(): void {
        const request = {
            Name: this.titleValue,
            OwnerId: this.responsibleUserSelected && this.responsibleUserSelected.id,
            OwnerName: this.responsibleUserSelected && this.responsibleUserSelected.ownerName,
            Measure: this.measureValue,
            Status: this.statusOptionSelected.id,
            Issues: this.issuesValue,
            NextSteps: this.nextStepsValue,
            ProjectListId: this.selectedList.id,
            NextStepDate: this.date,
            Archived: false,
        };
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.getOwnerId(request);
            this.$store.dispatch('actionSetSelectedListFromFilter', this.$store.state.filterLists);
        }
    }

    saveProject(request: any): void {
        if (this.isEditing) {
            request.id = this.recordId;
            ApiRequestor.put(`EditProject/${request.id}`, request)
                .then((res) => {
                    if (res.data.success) {
                        (this.$refs.closeBtn as HTMLElement).click();
                        this.$store.dispatch('setRefreshProjects', true);
                    }
                    else {
                        alert(res.data.message);
                    }
                })
                .catch((e) => console.log(e.message));
        }
        else {
            ApiRequestor.post('AddProject', request)
                .then((res) => {
                    if (res.data.success) {
                        (this.$refs.closeBtn as HTMLElement).click();
                        this.$store.dispatch('setRefreshProjects', true);
                    }
                    else {
                        alert(res.data.message);
                    }
                })
                .catch((e) => console.log(e.message));
        }
    }

    validateAddProject(request: any): boolean {
        for (const prop in request) {
            if (request[prop] == undefined || (request[prop] == '' && prop != 'Archived')) {
                return false;
            }
        }
        return true;
    }

    getOwnerId(requestSave: any): void {
        const owner = this.responsibleUsers.find((user) => user.id == requestSave.OwnerId);
        if (owner != undefined) {
            requestSave.OwnerId = owner.id;
            this.saveProject(requestSave);
        }
        else {
            createAutoClosingAlert('#saveWithInvalidUserAlert');
        }
    }

    cleanForm(): void {
        this.editRecord = {} as Project;
        this.recordId = '';
        this.titleValue = '';
        this.measureValue = '';
        this.issuesValue = '';
        this.isSelectedListChanged = false;
        this.nextStepsValue = '';
        this.responsibleUserSelected = {} as User;
        this.statusOptionSelected = {
            id: 1,
            color: '#009933',
            statusName: 'Active',
            icon: 'fa-circle'
        };
        this.listOptionSelected = '';
        this.selectedList =  this.selectedListFromFilter?.length === 1 ? this.selectedListFromFilter[0] : 
            this.selectedListFromFilter?.id ? this.selectedListFromFilter : {} as ProjectList;
        this.date = new Date().toISOString().slice(0, 10);
        this.$store.dispatch('setEdit', false);
        this.archived = false;
        this.inputTitleSet = false;
        this.addUserSet = false;
        this.measureSet = false;
        this.statusSet = Object.values(this.selectedListFromFilter).some((v) => v !== null && typeof v !== 'undefined');
        this.issuesSet = false;
        this.listSelectSet = Object.values(this.selectedListFromFilter).some((v) => v !== null && typeof v !== 'undefined');
        this.NextStepsSet = false;
        this.$v.$reset();
    }

    isListHidden(): boolean {
        return !this.statusSelected && !this.isEditing;
    }

    dateFormat(value: string) {
        return moment(value).format('DD-MMM-YY');
    }

    mounted(): void {
        this.getDropdownLists();
        this.cleanForm();
        $(<Element>this.$refs.addProjectModal).on('hidden.bs.modal', () => {
            this.cleanForm();
            // navigate to projects/actions page after closing the modal
            this.$router.push({ name: <string>this.$route.name }).catch((er) => er);
        });
    }
}
</script>
